import axios from 'axios';

//const getReqDataPath = 'http://localhost:8888/.netlify/functions/getReqData';
const getReqDataPath = 'https://www.sela.co.il/.netlify/functions/getReqData';

export async function getCountry() {
    try {
        let res = await axios.get(getReqDataPath);
        return res.data;
    }
    catch (error) {
        console.error('An error occurred calling the filter service:', error);
        return "NA";
    }
}


export function addDynamicStyle(country) {
    const style = document.createElement('style');

    if (country == "IL") {
      style.innerHTML = `
        .israelOnly {
          display:block;
        }

        .restOfWorld {
          display:none;
        }
      `;
    }
    else {
      style.innerHTML = `
        .israelOnly {
          display:none;
        }

        .restOfWorld {
          display:block;
        }
      `;
    }

    document.head.appendChild(style);
}