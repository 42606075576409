<template>
  <div>
    <topnav :country="country"></topnav>


    <div class="know-top-wrap">
      <div class="know-frame top"></div>
      <h1 class="txt80">Sela Events</h1>
      <div class="know-frame bot"></div>
    </div>

    <div class="limited-content-wrapper mb100px left-container events-filter">
      <!-- *** Filter *** -->
      <FilterForm dataType="events" showRegions showAllVendors filterPath="filterEvents" @refresh-results="refreshResults"></FilterForm>
    </div>


    <div class="event-group limited-content-wrapper" v-if="futureEvents.length">
      <div class="event-group-head-wrap ">
        <h1 class="txt54 upercase mb60px">
          <span class="lighter new-line">Upcoming</span>
        </h1>
      </div>
      <div class="events-wrap-page">
        <linkpath v-for="event in futureEvents" :key="event._id" :to="eventPath(event)" class="event-box"><img
            :src="event.thumbnail.imageURL || event.cover.imageURL" class="event-box-img" />
          <div class="event-box-txt-wrap">
            <div class="event-box-date">
              <p class="event-box-day">{{ new Date(event.date).getDate() }}</p>
              <p class="event-box-mon">
                {{
                  new Date(event.date).toLocaleString("en-US", {
                    month: "short",
                  })
                }}
              </p>
              <p class="event-box-year">
                {{ new Date(event.date).getFullYear() }}
              </p>
            </div>
            <p class="event-box-item">{{ event.title }}</p>
            <div class="event-info-box">
              <p>{{ event.blurb }}</p>
            </div>
          </div>
        </linkpath>

      </div>
    </div>
    <div class="event-group limited-content-wrapper past" v-if="pastEvents.length">
      <div class="event-group-head-wrap">
        <h4 class="txt54 upercase mb60px">
          <span class="lighter new-line">Past</span>
        </h4>
      </div>
      <div class="events-wrap-page">
        <linkpath v-for="event in pastEvents" :key="event._id" :to="eventPath(event)" class="event-box"><img
            :src="event.thumbnail.imageURL || event.cover.imageURL" class="event-box-img" />
          <div class="event-box-txt-wrap">
            <div class="event-box-date">
              <p class="event-box-day">{{ new Date(event.date).getDate() }}</p>
              <p class="event-box-mon">
                {{
                  new Date(event.date).toLocaleString("en-US", {
                    month: "short",
                  })
                }}
              </p>
              <p class="event-box-year">
                {{ new Date(event.date).getFullYear() }}
              </p>
            </div>
            <p class="event-box-item">{{ event.title }}</p>
            <div class="event-info-box">
              <p>{{ event.blurb }}</p>
            </div>
          </div>
        </linkpath>

      </div>
    </div>
    <botfooter :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import Linkpath from "../components/Linkpath.vue";
import { buildPath } from "@/utils/buildPath";
import { getMetatitle, getMetadesc } from "@/utils/getMetadata";
import FilterForm from "../components/FilterForm.vue";

export default {
  props: {
    country: String
  },
  components: {
    Topnav,
    Botfooter,
    Linkpath,
    FilterForm
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: getMetatitle("events"),
        description: getMetadesc("events")
      })
    };
  },
  name: "EventsIndex",
  created() {
    this.initData()
  },
  data: () => ({
    events: [],
  }),
  methods: {
    buildPath,
    initData() {
      this.events = this.$local.events;
    },
    refreshResults(results) {
      if (results)
        this.events = results;
      else
        this.initData();
    },
    eventPath(event) {
      const res = this.buildPath(`event/${event.slug}`);
      if (event.externalLink)
        return event.externalLink;
      else
        return res;
    },
  },
  computed: {
    pastEvents() {
      if (!this.events)
        return [];
      function sortPast(a, b) {
        return new Date(b.date) - new Date(a.date);
      }
      return this.events.filter((e) => new Date(e.date) < new Date()).sort(sortPast);
    },
    futureEvents() {
      if (!this.events)
        return [];
      function sortFuture(a, b) {
        return new Date(a.date) - new Date(b.date);
      }
      return this.events.filter((e) => new Date(e.date) > new Date() && !e.draft).sort(sortFuture);
    },
  },
};
</script>


<style scoped>

</style>