<template>
  <div>
    <topnav :country="country"></topnav>
    <div class="cus-top">
      <div class="cus-side-line"></div>
      <div class="cus-top-txt-wrap">
        <h1 class="txt54 no">Sela in the news</h1>
        <p class="know-page-sub nomarg">
          We will continue to push the needle with cloud technologies
        </p>
        <p>Here is what the media has to say</p>
        <router-link :to="buildPath('contact')" class="btn lowcase mt30px wide">Talk to us
        </router-link>
      </div>
      <div class="cus-top-frame">
        <img src="../assets/inthenews2.jpg" class="cus-img" />
        <div></div>
      </div>
    </div>


    <div class="news-list">
      <div class="news-item" v-for="news in $local.inTheNews" :key="news._id">
        <div class="news-cover">
          <img :src="news.cover.imageURL" :alt="news.title" class="newsitem-img" />
          <img :src="news.logo.imageURL" height="30" :alt="news.title" class="newsitem-logo" />
        </div>
        <div class="news-divider"></div>
        <div class="news-content">
          <h3 :class="news.language.value" class="point-head">{{ news.title }}</h3>
          <div :class="news.language.value" class="lighter nomarg" v-html="news.shortBlurb"></div>
          <a class="read-more" :href="news.link" target="_blank">Read More</a>
        </div>
      </div>
    </div>

    <botfooter  :country="country"></botfooter>
  </div>
</template>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import { buildPath } from "@/utils/buildPath";
import { getMetatitle, getMetadesc } from "@/utils/getMetadata";

export default {
  props: {
    country:String
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: getMetatitle("news"),
        description : getMetadesc("news")
      })
    };
  }, 
  methods: {
    buildPath
  },
  components: {
    Topnav,
    Botfooter,
  },
  name: "Customer",
};
</script>

<style scoped>
.news-list {
  display: flex;
  flex-direction: column;
  gap: 100px;
  width: 875px;
  margin: 30px auto 30px;
}

.news-item {
  display: flex;
}

.news-cover {
  flex-basis: 200px;
  display: flex;
  flex-direction: column;
  gap:40px;
}

.newsitem-img {
  height: 200px;
  width: 200px;
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
}

.news-divider {
  border-right: 14px solid var(--light-blue);
  margin-left: 40px;
  margin-right: 25px;
}

.Hebrew {
  direction: rtl;
}


@media screen and (max-width: 767px) {
  
  .news-list {
    gap: 150px;
  }

  .news-item {
    flex-direction: column;
  }

  .news-cover {
    align-items: center;
  }

  .newsitem-logo {
    max-width: 200px;
  }

  .news-divider {
    border-bottom: 14px solid var(--light-blue);
    border-right: 0 solid var(--light-blue);
    margin: 24px 0 6px 0;
  }
}

@media screen and (max-width: 991px) {
  .news-list {
    width: 90%;
  }
}
</style>
