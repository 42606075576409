<template>
  <div>
    <topnav :country="country"></topnav>

    <div class="cus-hero">
      <div class="cus-hero-side-line"></div>
      <div class="cus-top-frame">
        <h1 class="txt54">
          Our Customer Stories
        </h1>
        <img src="../assets/chat.svg" class="cust-chat" />
      </div>
      <div class="cus-hero-side-empty"></div>
    </div>


    <!-- *** Filter *** -->
    <FilterForm dataType="customers"  showRegions showVendors showCategories showCustomers showIndustries crowded filterPath="filterCustomers" @refresh-results="refreshResults"></FilterForm>

 

    <div class="point-wrap list" v-for="client in customers" :key="client._id">
      <div class="point-img-wrap">
        <img :src="client.logo.imageURL" :alt="client.name" class="point-img" />
      </div>
      <div class="point-txt-wrap right-line-blue">
        <h3 class="point-head">{{ client.name }}</h3>
        <div class="lighter nomarg" v-html="client.blurb"></div>
        <router-link :to="customerPath(client)" class="read-more">
          Read More
        </router-link>
      </div>
    </div>
    <div class="gray-back mt120px">
      <div class="cus-bot-wrap">
        <div class="cus-bot-frame">
          <h4 class="txt40">Sela understands clouds… deeply</h4>
          <p class="lighter">
            The cloud is a complex character. In fact, several complex
            characters. Continuously changing, growing, adapting. Interacting.
            Communicating. It – they - can be volatile and unpredictable. They
            can be influenced by factors beyond their initial intention. They
            can be influenced by each other.
            <br />Just llike people and communities. Some clouds are good for
            some purposes, and others for others. Just like people. We
            understand their advantages and limitations, and how to work with
            them. We appreciate them and their incredible magical strengths. And
            understand their limitations. We build, nurture, optimize clouds and
            hold them accountable. So you don’t need to.
          </p>
          <router-link :to="buildPath('contact')" class="btn lowcase mt30px wide">
            Talk to an expert
          </router-link>
        </div>
        <div class="cus-bot-img-wrap"><img src="../assets/multipleClouds.jpg" /></div>
      </div>
    </div>
    <botfooter :country="country"></botfooter>
  </div>
</template>

<style scoped>

.point-wrap.list .point-img {
  top: 0;
  max-width: 280px;
  max-height: 140px;
}

.point-img-wrap {
  height: auto;
}


@media screen and (min-width: 767px) {
  .point-wrap.list:nth-child(odd) {
    flex-direction: row;
  }
}

@media screen and (max-width: 767px) {
  .point-txt-wrap {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) {
  .point-img-wrap {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .filter-container .multi-select {
    max-width: 350px;
  }

  .filter-container .multi-select.region-select {
    max-width: 170px;
  }
}
</style>

<script>
import Botfooter from "@/components/Botfooter.vue";
import Topnav from "@/components/Topnav.vue";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { buildPath } from "@/utils/buildPath";
import { getMetatitle, getMetadesc } from "@/utils/getMetadata";
import FilterForm from "../components/FilterForm.vue";

export default {
  props: {
    country: String
  },
  components: {
    Topnav,
    Botfooter,
    FilterForm
  },
  created() {
    this.initData()
  },
  data: () => ({
    customers: []
  }),
  methods: {
    buildPath,
    initData() {
      this.customers = this.$local.customers.filter((c) => c.customers); // .customers = show success story
    },
    refreshResults(results) {
      if (results)
        this.customers = results;
      else
        this.initData();
    },
    customerPath(client) {
      const res = this.buildPath(`${client.tech}/customer-story/${client.slug}`);
      return res;
    },
  },
  metaInfo() {
    return {
      ssrAppId: "sela",
      ...this.seo({
        title: getMetatitle("customers"),
        description: getMetadesc("customers")
      })
    };
  },
  computed: {
  },
  name: "Customer",
};
</script>
